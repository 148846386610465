.dashboard-container {
    display: flex;
    font-family: GoogleSans;
    background-color: white;
    width: 100vw;
}

.sidebar {
    min-width: 240px;
    border-right: 1px solid #ccc;
    height: 100vh;
    margin-top: 4px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li a {
    display: block;
    padding: 1rem;
    text-decoration: none;
    color: #3c4043;
}

.sidebar li a.active {
    background-color: #f0f0f0;
}

.sidebar li p {
    font-size: 1rem;
    padding: 0;
    margin: 0.5rem;
}

.content {
    flex-grow: 1;
    padding-top: 20px;
}

.agents-list button {
    margin-top: 20px;
    /* Additional button styling */
}

/* Style for @reach/dialog */
[data-reach-dialog-overlay] {
    background: hsla(0, 0%, 0%, 0.33);
    display: flex;
    justify-content: center;
    align-items: center;
}

[data-reach-dialog-content] {
    width: 50vw;
    padding: 32px;
    background: white;
    outline: none;
}

.my-agents-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

.my-agents-container > .claim-button {
    background-color: #1976d2;
    color: white;
    width: 15rem;
    padding: 0.5rem;
    margin-bottom: 2rem;
}

.my-agents-container > p {
    margin-top: 2rem;
}

.claims-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: fit-content;
}

.my-agents-container h3 {
    color: #1976d2; /* Theme color for headers */
    /* margin: 0; */
    width: fit-content;
}

.claims-list p {
    color: #333; /* Dark color for text */
    margin: 0;
    width: fit-content;
    line-height: 2;
    text-overflow: ellipsis;
}

.claim {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: white; /* A contrasting background for individual claim */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-radius: 4px;
    width: 100%;
}
.claim img {
    width: 2rem;
    height: auto;
    margin-right: 1rem;
    border-radius: 4px; /* Optional: Rounds the corners of the logo */
}

.claim a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    flex-grow: 1; /* Allows the link content to fill the space */
}

.claim a > span {
    margin-right: 1rem;
    white-space: nowrap; /* Keeps the agent name on one line */
}

.claim .code {
    background-color: #e0e0e0;
    padding: 0.5rem 0.5rem;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    margin-left: auto; /* Pushes the code to the right */
}

.claim > .verify-button {
    background-color: #4caf50; /* A color that indicates 'go' or 'success' */
    color: white;
    margin-left: 1rem;
    width: 6rem;
}

.claim > .verify-button-verifying {
    background-color: #D3D3D3; /* A color that indicates 'go' or 'success' */
    color: white;
    margin-left: 1rem;
    width: 6rem;
}

.claim > a > p {
    text-overflow: ellipsis;  /* Use ellipsis for overflowed text */
    overflow: hidden;         /* Hide overflowed content */
    white-space: nowrap;      /* Prevent text from wrapping to the next line */
    max-width: 35rem;             /* Set a fixed width */
    max-lines: 1; 
}

.claim > .copy-button {
    padding: 0;
    margin: 0;
}

.claim > .verify-button:hover {
    background-color: #388e3c; /* A darker shade on hover */
}

.claim > .verify-button-verifying:hover {
    background-color: #D3D3D3; /* A darker shade on hover */
}

.claim > button {
    margin-right: 1rem;
}

/* Additional styles for buttons */
button {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    opacity: 0.8;
}

/* Styles for the agent code and copy icon */
.claim code {
    background-color: #e0e0e0;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    margin-right: 1rem;
    font-family: 'Courier New', Courier, monospace;
}

.ContentCopyIcon {
    /* Adjust the icon size if necessary */
    width: 24px;
    height: 24px;
}

.logout-button {
    background-color: #4caf50; /* A color that indicates 'go' or 'success' */
    color: white;
    margin-left: 1rem;
    width: 6rem;
}

.agent-list-title-text {
    color: #212123;
    font-size: 1.5rem;
    width: 100%;
}