.request-demo-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 20;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 #00000030;
}

.request-demo-container .title {
    font-family: Gilroy-Bold;
}

.request-demo-container .description {
    font-family: Gilroy-Regular;
    font-size: 18px;
    width: fit-content;
}