/* App.css */
.App {
  text-align: center;
  background: linear-gradient(90deg, #0062ff, #da61ff);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 60px;
}

.loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
  font-family: 'Helvetica-Medium';
  src: url('../public/fonts/Helvetica_CE_Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('../public/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../public/fonts/Gilroy-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: url('../public/fonts/Gilroy-Heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('../public/fonts/Gilroy-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../public/fonts/Gilroy-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../public/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(../public/fonts/ProductSans-Regular.woff) format('woff');
}

