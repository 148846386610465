.home-container {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    margin: 100 auto 0 auto;
    align-items: center;
    width: 100%;
} 

.head-container {
    display: flex;
    flex-direction: column; /* Start with a vertical layout */
    align-items: top; /* Center items when stacked vertically */
    height: auto; /* Use auto for dynamic height based on content */
    row-gap: 1rem;
}

@media (min-width: 1300px) {
    .head-container {
        flex-direction: row; /* Switch to horizontal layout on larger screens */
        align-items: top; /* Adjust alignment as needed for your design */
    }

    .head-container .head-data-container {
        margin: 0 0 0 2rem; /* Keep the margin for horizontal layout */
    }
}

  

.head-container .head-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 2rem;
}

.head-container .head-slogan {
    font-size: 60px;
    font-family: Gilroy-Bold;
    margin: 0;
}

.head-container .head-description {
    font-size: 30px;
    font-family: Gilroy-Bold;
    font-weight: 0.5;
    margin: 0 0 1rem 0;
}

.head-container .head-feature-list {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.head-container .head-feature-list p {
    margin: 0 0 1rem 0;
    font-size: 20px;
    font-family: Gilroy-Bold;
    text-align: left;
}

.demo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    align-items: center;
}

@media (min-width: 1200px) {
    .demo-container {
        flex-direction: row; /* Switch to horizontal layout on larger screens */
        align-items: top; /* Adjust alignment as needed for your design */
    }
}

.home-container .demo-container img {
    width: 40%;
}

.home-container .demo-container img:hover {
    width: 45%;
}

.home-container .feature-title {
    font-size: 30px;
    font-family: Gilroy-Bold;
}
.login-container {
    width: 400px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    margin: 0 auto 0 auto;
}

.shadowbox {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 #00000030; /* Adjust these values to fit your design */
    transition: 0.3s; /* Smooth transition for hover effect */
  }
  
  .shadowbox:hover {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
  }
  